import { createEffect, createSignal } from 'solid-js';
import { BATHROOMS, BEDROOMS, BEDS_AND_BATHS } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
import { SearchBase } from './SearchBase';
import { SearchRadio } from './SearchRadio';

interface SearchBedBathsProps {
  beds: number;
  baths: number;
  onChange: (value: any) => void;
  valueDisplay?: string;
  bedOptions?: { label: string; value: string }[];
  bathOptions?: { label: string; value: string }[];
}

export const SearchBedBaths = (props: SearchBedBathsProps) => {
  const { t } = useLocalization();
  const [beds, setBeds] = createSignal<any>();
  const [baths, setBaths] = createSignal<any>();

  const onApply = () => {
    props.onChange?.({
      minBaths: baths(),
      minBeds: beds(),
    });
  };

  const onOutsideClick = () => {
    setBeds(props.beds);
    setBaths(props.baths);
  };

  createEffect(() => {
    setBeds(props.beds);
    setBaths(props.baths);
  });

  return (
    <>
      <div class="hidden lg:block">
        <SearchBase title={props.valueDisplay || t(BEDS_AND_BATHS)} onApply={onApply} onOutsideClick={onOutsideClick}>
          <SearchRadio title={t(BEDROOMS)} options={props.bedOptions} value={beds} onChange={setBeds} />
          <SearchRadio title={t(BATHROOMS)} options={props.bathOptions} value={baths} onChange={setBaths} />
        </SearchBase>
      </div>
      <div class="block lg:hidden">
        <SearchRadio
          title={t(BEDROOMS)}
          options={props.bedOptions}
          value={beds}
          onChange={(value) => {
            setBeds(value);
            onApply();
          }}
        />
        <SearchRadio
          class="mt-4"
          title={t(BATHROOMS)}
          options={props.bathOptions}
          value={baths}
          onChange={(value) => {
            setBaths(value);
            onApply();
          }}
        />
      </div>
    </>
  );
};
