import { Show, createSignal, createEffect } from 'solid-js';
import { Select } from '~/components/ui';
import { SearchRangeProps } from '~/types/SearchListing';
import { SearchBase } from './SearchBase';

interface SearchValue {
  start?: string;
  end?: string;
}

export const SearchRange = (props: SearchRangeProps) => {
  const [data, setData] = createSignal<SearchValue>({});

  createEffect(() => {
    setData(props.value);
  });

  const onSelect = (value: string, type: 'start' | 'end') => {
    const newData = {
      ...data(),
      [type]: value,
    };
    setData(newData);
  };

  const onApply = () => {
    props.onChange?.(data());
  };

  const onOutsideClick = () => {
    setData(props.value);
  };

  return (
    <>
      <div class="hidden lg:block">
        <SearchBase title={props.valueDisplay || props.title} onApply={onApply} onOutsideClick={onOutsideClick}>
          <div class="w-full">
            <Show when={props.title}>
              <div class="h-9 bg-[#F5F5F9] px-4 text-xs leading-9 text-[#6B738A] sm:px-6">{props.title}</div>
            </Show>
            <div class="flex flex-col space-y-4 bg-white p-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 sm:p-6">
              <Show when={props.start}>
                <div class="w-full sm:w-auto">
                  <div class="mb-2 text-xs text-[#6B738A]">{props.start?.title}</div>
                  <Select
                    onChange={(e) => onSelect(e, 'start')}
                    class="w-full bg-[#F3F3F5] sm:w-120px"
                    placeholder={props.start?.placeholder}
                    options={props.start?.options || []}
                    value={data().start}
                  />
                </div>
              </Show>
              <Show when={props.start && props.end}>
                <div class="hidden text-base text-[#6B738A] sm:block">-</div>
                <div class="flex w-full items-center justify-center sm:hidden">
                  <div class="h-px w-8 bg-[#6B738A]" />
                </div>
              </Show>
              <Show when={props.end}>
                <div class="w-full sm:w-auto">
                  <div class="mb-2 text-xs text-[#6B738A]">{props.end?.title}</div>
                  <Select
                    onChange={(e) => onSelect(e, 'end')}
                    class="w-full bg-[#F3F3F5] sm:w-120px"
                    placeholder={props.end?.placeholder}
                    options={props.end?.options || []}
                    value={data().end}
                  />
                </div>
              </Show>
            </div>
          </div>
        </SearchBase>
      </div>
      <div class="block lg:hidden">
        <div class="w-full">
          <div class="flex items-center gap-3">
            <Show when={props.start}>
              <div>
                <div class="h-9 text-base font-medium leading-9 text-title-gray">{props.start?.title}</div>
                <Select
                  onChange={(e) => {
                    onSelect(e, 'start');
                    onApply();
                  }}
                  class="w-full bg-[#F3F3F5]"
                  placeholder={props.start?.placeholder}
                  options={props.start?.options || []}
                  value={data().start}
                />
              </div>
            </Show>
            <Show when={props.start && props.end}>
              <div class="mt-9 text-base text-[#6B738A]">-</div>
            </Show>
            <Show when={props.end}>
              <div>
                <div class="h-9 text-base font-medium leading-9 text-title-gray">{props.end?.title}</div>
                <Select
                  onChange={(e) => {
                    onSelect(e, 'end');
                    onApply();
                  }}
                  class="w-full bg-[#F3F3F5]"
                  placeholder={props.end?.placeholder}
                  options={props.end?.options || []}
                  value={data().end}
                />
              </div>
            </Show>
          </div>
        </div>
      </div>
    </>
  );
};
