import { Show, For, createSignal, createEffect } from 'solid-js';
import { SearchItemProps } from '~/types/SearchListing';
import { cn } from '~/utils/classnames';

export const SearchRadio = (props: SearchItemProps) => {
  const [data, setData] = createSignal<string | number>();

  createEffect(() => {
    setData(props.value);
  });

  const handleChange = (value: string | number) => {
    setData(value);
    props.onChange?.(value);
  };

  return (
    <div class={cn('w-full', props.class)}>
      <Show when={props.title}>
        <div class="hidden h-9 bg-[#F5F5F9] px-4 text-xs leading-9 text-[#6B738A] sm:px-6 lg:block">{props.title}</div>
      </Show>
      <div class="flex flex-col bg-white lg:p-4">
        <div class="h-9 text-base font-medium leading-9 text-title-gray">{props.title}</div>
        <div class="flex w-full flex-wrap overflow-hidden rounded-sm border border-[#BCC0CE] sm:w-fit">
          <Show when={props.options?.length}>
            <For each={props.options}>
              {(item) => {
                return (
                  <div
                    onClick={() => {
                      handleChange(item.value);
                    }}
                    class={cn(
                      'flex-1 cursor-pointer border-b border-[#BCC0CE] px-3 py-2 text-center text-sm sm:flex-none sm:border-b-0 sm:border-r sm:px-4 sm:py-3 sm:text-base',
                      'last:border-b-0 sm:last:border-r-0',
                      item.value === data() && 'border border-primary-color bg-[#0C48BC0F]'
                    )}>
                    {item.label}
                  </div>
                );
              }}
            </For>
          </Show>
        </div>
      </div>
    </div>
  );
};
