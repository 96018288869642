import { Show, For, createSignal, createEffect } from 'solid-js';
import { Checkbox } from '~/components/ui';
import { CheckboxItem } from '~/types/SearchListing';
import { SearchBase } from './SearchBase';

export interface Option {
  label: string;
  value: string;
}

export const SearchCheckbox = (props: CheckboxItem) => {
  const [data, setData] = createSignal<string[]>([]);

  createEffect(() => {
    setData(props.value || []);
  });

  const handleChange = (key: string, e: boolean) => {
    let newData = [...data()];
    if (!e) {
      newData = newData.filter((item) => item !== key);
    } else {
      newData.push(key);
    }
    setData(newData);
  };

  const onApply = () => {
    props.onChange?.(data());
  };

  const onOutsideClick = () => {
    setData(props.value || []);
  };

  return (
    <>
      <div class="hidden lg:block">
        <SearchBase title={props.valueDisplay || props.title || ''} onApply={onApply} onOutsideClick={onOutsideClick}>
          <div class="w-full max-w-full overflow-hidden">
            <Show when={props.title}>
              <div class="h-9 bg-[#F5F5F9] px-4 text-xs leading-9 text-[#6B738A] sm:px-6">{props.title}</div>
            </Show>
            <div class="flex flex-col gap-4 bg-white p-4 sm:gap-[22px] sm:p-6">
              <For each={props.options}>
                {(item) => {
                  return (
                    <div class="min-w-0">
                      <Checkbox
                        onInput={(e) => handleChange(item.value, e)}
                        showLabel
                        label={item.label}
                        checkedValue={data()?.includes(item.value)}
                        class="break-words"
                        labelClass="text-sm sm:text-base break-words"
                      />
                    </div>
                  );
                }}
              </For>
            </div>
          </div>
        </SearchBase>
      </div>
      <div class="block lg:hidden">
        <div class="w-full max-w-full overflow-hidden">
          <Show when={props.title}>
            <div class="h-9 text-base font-medium leading-9 text-title-gray">{props.title}</div>
          </Show>
          <div class="flex flex-col gap-4 bg-white">
            <For each={props.options}>
              {(item) => {
                return (
                  <div class="min-w-0">
                    <Checkbox
                      onInput={(e) => {
                        handleChange(item.value, e);
                        onApply();
                      }}
                      showLabel
                      label={item.label}
                      checkedValue={data()?.includes(item.value)}
                      class="break-words"
                      labelClass="text-sm sm:text-base break-words"
                    />
                  </div>
                );
              }}
            </For>
          </div>
        </div>
      </div>
    </>
  );
};
