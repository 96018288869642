import { PROPERTIES } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
import { useTitle } from '~/hooks/useTitle';
import { PropertiesSearchList } from '~/pages/properties/PropertiesSearchList';

export default function Properties() {
  const { t } = useLocalization();
  useTitle({ title: t(PROPERTIES) });
  return (
    <div class="min-h-[1000px] w-full">
      <PropertiesSearchList />
    </div>
  );
}
